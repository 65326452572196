import * as React from "react"
import { Link } from "gatsby"

// Import Images
import JBSLogo from '../images/jbs-logo.svg'
import JackieEd from '../images/jackie-and-ed.svg'

// Import Components
import Layout from "../components/layout"
import Seo from "../components/seo"

const Sent = () => (
  <Layout>
    <Seo title="Guest Book Sent - Jackie's Baby Shower" />
    <div className="section">

      <Link to="/" className="logo">
       <img src={JBSLogo} alt="Jackie's Baby Shower" />
      </Link>

      <div className="head-btn">
        <a href="https://www.babylist.com/baby-jackie-thring" target="_blank" className="btn red">
          <span className="shadow"></span>
          <span className="button">Registry</span>
        </a>
      </div>

      <div className="section no-bottom">
        <div className="container text-center">
          <div className="box full-height background-green box-radius">
            <div className="content-wrap-middle">
              <div className="content">
                <h1 className="all-caps text-green text-shadow no-top">Message Sent!</h1>
                <p className="text-black no-top-bottom">Thanks for filling out the guest book.</p>
                <div className="link-box no-bottom">
                  <Link to="/" className="btn green">
                    <span className="shadow"></span>
                    <span className="button">Back Home</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section footer text-center">
      <div className="container">
        <span className="jackie-ed no-top">
          <img src={JackieEd} alt="Jackie and Ed illustration" />
        </span>
        <p className="tiny no-top-bottom">
          <span className="text-grey">Custom Designed for Jackie.</span><br/>
          <span className="text-black">Made by <a href="http://www.mywebvite.com" target="_blank" className="text-link black">MyWebvite.</a></span>
        </p>
      </div>
    </div>
  </Layout>
)

export default Sent
